import StoryChief from '../../storychief/index';
import {
  CHANNEL_API,
  CHANNEL_DRUPAL,
  CHANNEL_FACEBOOK,
  CHANNEL_MAILCHIMP,
  CHANNEL_MEDIUM,
  CHANNEL_TWITTER,
  CHANNEL_WORDPRESS,
  CHANNEL_BLOGGER,
  CHANNEL_FORK,
  CHANNEL_SHOPIFY,
  CHANNEL_RSS,
  CHANNEL_AMP,
  CHANNEL_CRAFT,
  CHANNEL_LINKEDIN,
  CHANNEL_HUBSPOT,
  CHANNEL_PUPPETEER,
  CHANNEL_CAMPAIGN_MONITOR,
  CHANNEL_ACTIVE_CAMPAIGN,
  CHANNEL_WEBFLOW,
  CHANNEL_COPY_PASTE,
  CHANNEL_WILLIAM,
  CHANNEL_JOOMLA,
} from '@/destinations/constants/Constants';

function getDestinationUrl(destination, story) {
  const channelType = destination ? destination.channel_type : '';
  switch (channelType) {
    case CHANNEL_TWITTER:
      return `https://twitter.com/${destination.display_name}`;
    case CHANNEL_FACEBOOK:
      return destination.info && destination.info.page ? destination.info.page.link : '';
    case CHANNEL_MEDIUM:
      return destination.info && destination.info.publication
        ? destination.info.publication.url
        : '';
    case CHANNEL_BLOGGER:
      return destination.info && destination.info.blog ? destination.info.blog.url : '';
    case CHANNEL_SHOPIFY:
      return `https://${destination.info.store_url}.myshopify.com/blogs/${destination.info.blog.handle}`;
    case CHANNEL_RSS:
      return `${StoryChief.publicBlogUrl}/rss/${destination.id}`;
    case CHANNEL_MAILCHIMP:
      return `${StoryChief.publicBlogUrl}/rss/mailchimp/${destination.id}`;
    case CHANNEL_CAMPAIGN_MONITOR:
      return `${StoryChief.publicBlogUrl}/rss/campaign-monitor/${destination.id}`;
    case CHANNEL_ACTIVE_CAMPAIGN:
      return `${StoryChief.publicBlogUrl}/rss/active-campaign/${destination.id}`;
    case CHANNEL_AMP: {
      if (story && story.primary_destination) {
        return getDestinationUrl(story.primary_destination, story);
      }
      return `${window.location.protocol}//${window.location.host}`;
    }
    case CHANNEL_LINKEDIN: {
      if (destination.info && destination.info.company) {
        return `https://www.linkedin.com/company/${destination.info.company.universalName}/`;
      }
      return null;
    }
    case CHANNEL_COPY_PASTE:
    case CHANNEL_FORK:
    case CHANNEL_WORDPRESS:
    case CHANNEL_API:
    case CHANNEL_CRAFT:
    case CHANNEL_DRUPAL:
    case CHANNEL_PUPPETEER:
    case CHANNEL_JOOMLA: {
      if (destination.info && destination.info.endpoint && destination.info.endpoint !== '') {
        const a = document.createElement('a');
        a.href = destination.info.endpoint;
        return `${a.protocol}//${a.host}`;
      }
      return `${window.location.protocol}//${window.location.host}`;
    }
    case CHANNEL_HUBSPOT:
      return destination.info && destination.info.blog ? destination.info.blog.absolute_url : '';
    case CHANNEL_WEBFLOW:
      return destination.info && destination.info.domains
        ? destination.info.domains.join(', ')
        : '';
    case CHANNEL_WILLIAM:
      return window.StoryChief.publicBlogUrl;
    default:
      return '';
  }
}

export default getDestinationUrl;
